import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import language from './i18n'
import App from './App.vue'

import '@/assets/styles/basic.css'
import '@/assets/styles/country.css'
import '@/assets/styles/element_theme.scss'

import '@/components/svgIcon/svgIcon.js'
import svgIcon from '@/components/svgIcon/svgIcon.vue'

const state = createPinia()

createApp(App).use(router).use(language).use(state).component('svg-icon', svgIcon).mount('#app')
