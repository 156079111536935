import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
export default {
  __name: 'svgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '16px'
    }
  },
  setup(__props) {
    const props = __props;
    const iconName = computed(() => `#icon-${props.name}`);
    const svgClass = computed(() => {
      if (props.name) {
        return `svg-icon icon-${props.name}`;
      }
      return 'svg-icon';
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", _mergeProps({
        class: svgClass.value
      }, _ctx.$attrs, {
        style: {
          color: __props.color,
          fontSize: __props.size
        }
      }), [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_1)], 16);
    };
  }
};