import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/notePosition',
    children: [
      {
        path: '/notePosition',
        name: 'NotePosition', // 短信定位
        component: () => import(/* webpackChunkName: "notePosition" */ '../views/notePosition/NotePositionView.vue'),
        meta: {
          navActive: '/notePosition'
        }
      },
      {
        path: '/sendNote',
        name: 'SendNote', // 发送短信
        component: () => import(/* webpackChunkName: "sendNoteView" */ '../views/notePosition/SendNoteView.vue'),
        meta: {
          navActive: '/notePosition'
        }
      },
      {
        path: '/sendNoteSucceed/:type',
        name: 'SendNoteSucceed', // 发送短信定位成功
        component: () => import(/* webpackChunkName: "sendNoteSucceed" */ '../views/notePosition/SendNoteSucceed.vue'),
        meta: {
          navActive: '/notePosition'
        }
      },
      {
        path: '/locationRecord/:type',
        name: 'LocationRecord', // 历史定位
        component: () => import(/* webpackChunkName: "locationRecord" */ '../views/locationRecord/LocationRecordView.vue'),
        meta: {
          navActive: '/notePosition'
        }
      },
      {
        path: '/phonePosition',
        name: 'PhonePosition', // 定位丢失的手机
        component: () => import(/* webpackChunkName: "phonePosition" */ '../views/phonePosition/PhonePositionView.vue'),
        meta: {
          navActive: '/phonePosition'
        }
      },
      {
        path: '/sendPhoneNote',
        name: 'SendPhoneNote', // 定位丢失的手机 发送短信
        component: () => import(/* webpackChunkName: "sendPhoneNote" */ '../views/phonePosition/SendPhoneNoteView.vue'),
        meta: {
          navActive: '/phonePosition'
        }
      },
      {
        path: '/linkPosition',
        name: 'LinkPosition', // 定位丢失的手机 发送短信
        component: () => import(/* webpackChunkName: "linkPosition" */ '../views/linkPosition/LinkPositionView.vue'),
        meta: {
          navActive: '/linkPosition'
        }
      },
      {
        path: '/linkSucceed',
        name: 'LinkSucceed', // 定位丢失的手机 发送短信
        component: () => import(/* webpackChunkName: "linkSucceed" */ '../views/linkPosition/LinkSucceedView.vue'),
        meta: {
          navActive: '/linkPosition'
        }
      },
      {
        path: '/user',
        name: 'User', // 定位丢失的手机 发送短信
        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserView.vue'),
        meta: {
          navActive: '/user'
        }
      }
    ]
  },
  {
    path: '/registerSucceed',
    name: 'registerSucceed',
    component: () => import(/* webpackChunkName: "registerSucceed" */ '../views/login/registerSucceed.vue'),
    meta: {
      // keepAlive: true, // 如果需要缓存就是true 不需要缓存就是false
      noRequiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
    meta: {
      // keepAlive: true, // 如果需要缓存就是true 不需要缓存就是false
      noRequiresAuth: true
    }
  },
  {
    path: '/tryOut',
    name: 'tryOut',
    component: () => import(/* webpackChunkName: "tryOut" */ '../views/tryOut/tryOutView.vue'),
    meta: {
      // keepAlive: true, // 如果需要缓存就是true 不需要缓存就是false
      noRequiresAuth: true
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 添加 beforeEach 导航守卫
router.beforeEach((to, from, next) => {
  if (to.name === 'LocationRecord') to.meta.navActive = `/${to.params.type}`
  if (to.name === 'SendNoteSucceed') to.meta.navActive = `/${to.params.type}`

  const userInfo = JSON.parse(localStorage.getItem('userInfo') as string)
  if (!to.meta.noRequiresAuth && !userInfo) {
    // 如果目标路由需要身份验证且用户未登录，则重定向到登录页面
    next('/login')
  } else {
    // 否则，允许导航继续
    next()
  }
})

export default router
