/*
 * @Author: tings
 * @Date: 2023-12-04 14:44:14
 * @LastEditors: [you name]
 * @LastEditTime: 2023-12-19 16:03:22
 * @Description:
 */
export const enum Names {
  SMS_LOCATION = 'SMS_LOCATION'
}
