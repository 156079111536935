import axios from 'axios';
import { increaseRequestCount, decreaseRequestCount } from '@/util/load';
// 白名单列表，存储不需要显示加载效果的请求
const whitelist = ['/aipen/files_directory/lists'];
const baseURL = process.env.VUE_APP_HTTP_DOMAIN;
// 1. 利用axios对象的方法create，去创建一个axios案例
// 2. requests就是axios
const requests = axios.create({
  // 配置对象
  baseURL,
  // baseURL: 'https://test-aipen-api.thwpmanage.com/index.php/api/', // 基础路径，发送请求的时候，路径当中会出现api
  timeout: 1000 * 60 // 请求超时的时间5s
});
// 请求拦截器：在发送请求之前，请求拦截器可以检测到，在请求发送之前处理一些事情
requests.interceptors.request.use(config => {
  // 判断本地是否有 token
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  if (userInfo.token && !config.headers.token) {
    // 如果有，则设置请求头中的 Authorization 字段
    config.headers.token = `${userInfo.token}`;
  }
  const lang = JSON.parse(localStorage.getItem('lang') || '{}');
  // 在 GET 请求中添加参数
  // if (config.method === 'get') {
  config.params = {
    ...config.params,
    lang: lang.value
  };
  // }
  // 在 POST 请求中添加参数
  // if (config.method === 'post') {
  //   config.data = {
  //     ...config.data,
  //     lang: lang.value
  //   }
  // }
  const url = config.url || '';
  if (!whitelist.includes(url)) {
    increaseRequestCount();
  }
  // config: 是配置对象，该对象包含一个属性-->headers请求头
  return config;
}, error => {
  decreaseRequestCount();
  return Promise.reject(error);
});
// 响应拦截器：
requests.interceptors.response.use(response => {
  const data = response.data;
  // 判断 code 字段是否为 401
  if (data.code === 401) {
    // 如果是，则跳转至登录页
    window.location.href = '/login';
  } else if (data.code !== 200 && data.code !== 402 && data.code !== 403) {
    ElMessageBox.close();
    ElMessage({
      type: 'warning',
      message: response?.data?.msg || '接口报错',
      center: true,
      dangerouslyUseHTMLString: true
    });
  }
  decreaseRequestCount();
  return response.data;
}, error => {
  console.log('error', error);
  const response = error.response || {};
  decreaseRequestCount();
  if (response.status === 402 || response.status === 403) return response.data;
  ElMessageBox.close();
  ElMessage({
    type: 'warning',
    message: response?.data?.msg || '接口报错',
    // message: '测试你的短信发送频率过高<br/>请在238小时22分钟后再发送短信',
    center: true,
    dangerouslyUseHTMLString: true
  });
  // 判断 code 字段是否为 401
  if (response.data.code === 401) {
    // 如果是，则跳转至登录页
    window.location.href = '#/login';
  }
  return response.data;
});
// 对外暴露
export default requests;