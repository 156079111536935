import { createI18n } from 'vue-i18n'
import zhCN from './locales/zh_CN'
import enUS from './locales/en_US'

const localLang = JSON.parse(localStorage.getItem('lang') as string) || null

const i18n = createI18n({
  legacy: false,
  locale: localLang ? localLang.value : 'en', // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    en: enUS
  }
})

export default i18n
