import { defineComponent, ref, effect } from 'vue';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import { useSmsLocation } from '@/store/index';
import HeaderCom from '@/components/HeaderCom.vue';
import { detail } from '@/api/apis';
import { ScreenSize } from '@/util/utils';
export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderCom
  },
  setup() {
    const route = useRoute();
    const activePath = ref(route.meta.navActive || '');
    const smsLocation = useSmsLocation();
    // console.log(smsLocation.notSubscribedDialog)
    smsLocation.getCountryList();
    smsLocation.getSubscriptionList();
    smsLocation.getCartSubscriptionList();
    // 路由更新
    onBeforeRouteUpdate(to => {
      activePath.value = to.meta.navActive || '';
      smsLocation.notSubscribedDialogChange(false);
      smsLocation.cartSubscribedDialogChange(false);
    });
    const closeNotSubscribedDialog = () => {
      if (route.name === 'User' && !smsLocation.isUser) {
        smsLocation.isUserChange(true);
      }
      smsLocation.notSubscribedDialogChange(false);
    };
    const closeCartSubscribedDialog = () => {
      smsLocation.cartSubscribedDialogChange(false);
    };
    const closeNoCountDialog = () => {
      smsLocation.closeNoCountDialogChange(false);
    };
    const getUserDetail = () => {
      detail().then(res => {
        smsLocation.userDetailChange(res.data);
      });
    };
    getUserDetail();
    const nextBuyCar = () => {
      // window.open(smsLocation.userDetail.trial_link)
      closeNoCountDialog();
      smsLocation.notSubscribedDialogChange(true);
    };
    // 谷歌埋点 - 购买按钮埋点id
    const buyBtnId = ['buy-ilocate-one', 'buy-ilocate-six', 'buy-ilocate-year'];
    const isCollapse = ref(smsLocation.screenSize <= ScreenSize.LARGE);
    const isShowNav = ref(false);
    // 监听屏幕宽度变化
    effect(() => {
      // console.log(smsLocation.screenSize, smsLocation.screenSize <= ScreenSize.LARGE)
      isCollapse.value = smsLocation.screenSize <= ScreenSize.LARGE;
      if (smsLocation.screenSize >= ScreenSize.LARGE) {
        isShowNav.value = true;
      }
    });
    const changeShowNav = () => {
      isShowNav.value = !isShowNav.value;
    };
    return {
      activePath,
      smsLocation,
      closeNotSubscribedDialog,
      closeNoCountDialog,
      closeCartSubscribedDialog,
      buyBtnId,
      nextBuyCar,
      isCollapse,
      isShowNav,
      changeShowNav
      // notSubscribedDialog,
      // notSubscribedDialogChange
    };
  }
});