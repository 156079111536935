import { defineComponent, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSmsLocation } from '@/store/index';
export default defineComponent({
  setup() {
    // const I18n = useI18n()
    const {
      t
    } = useI18n();
    const smsLocation = useSmsLocation();
    // locale.value = 'en-us'
    // if (localLang && localLang.value !== locale.value) {
    //   locale.value = localLang.value
    // }
    // console.log('locale', locale.value)
    // console.log('I18n', I18n)
    watchEffect(() => {
      document.title = t('title');
    });
    const checkScreenSize = () => {
      smsLocation.changeScreenSize(window.innerWidth);
    };
    // 监听窗口大小变化事件
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();
  }
});