// import { ElLoading } from 'element-plus'
// 创建全局的 loading 实例
let loadingInstance;
// 计数器，用于跟踪当前正在进行的请求数量
let requestCount = 0;
// 增加请求计数
export function increaseRequestCount() {
  if (requestCount === 0) {
    // 如果当前没有进行的请求，显示 loading
    loadingInstance = ElLoading.service();
  }
  requestCount++;
}
// 减少请求计数
export function decreaseRequestCount() {
  requestCount--;
  if (requestCount <= 0) {
    requestCount = 0; // 将负数计数重置为 0
    if (loadingInstance) {
      loadingInstance.close();
    }
  }
}